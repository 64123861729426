import React from 'react'
import styled from 'styled-components'
import layout from '../../services/layout'

const GridOrPortrait = styling => (`
  &.grid-view {
    ${styling}
  }
  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    && {
      ${styling}
    }
  }
`)

const OuterContainer = styled.div`
  display: flex;

  ${GridOrPortrait(`
    margin: 0;
    position: absolute;
    top: -15px;
    left: 0;
  `)}
`

const Label = styled.span`
  font-weight: bold;
  background: #2e2e2e;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 50px;

  ${GridOrPortrait(`
    border-radius: 0 50px 50px 0;
  `)}

  &:last-child {
    margin-right: 10px;
  }

  i {
    margin: 0;
  }
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`

function AdPills({ isSold, layoutClass }) {
  return <OuterContainer className={layoutClass}>
    <InnerContainer className={layoutClass}>
      {isSold && <Label className={layoutClass} style={{ background: layout.colors.primary }}>Sold</Label>}
    </InnerContainer>
  </OuterContainer>
}

export default AdPills;