import React from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import Countdown from './Countdown'
import layout from '../../services/layout'
import InvertedPopup from '../furniture/InvertedPopup';

const GridOrPortrait = styling => (`
  &.grid-view {
    ${styling}
  }
  &.grid-view, &.list-view {
    @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
      && {
        ${styling}
      }
    }
  }
`)

const OuterContainer = styled.div`
  margin: -10px 0 -10px 20px;
  display: flex;

  &.ad-details {
    margin: 0;
  }

  ${GridOrPortrait(`
    margin: 0;
    position: absolute;
    top: -16px;
    right: 0;
  `)}

  &&&.grid-view {
    top: -15px;
  }

  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    &&&.list-view {
      top: -18px;
    }
  }
`

const InnerContainer = styled.div`
  background-color: #2e2e2e;
  border-radius: 50px;
  font-size: 12px;
  display: flex;
  align-items: center;

  &.list-view, &.ad-details {
    padding: 12px 15px 12px 15px;
  }

  &.has-auction {
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
  }

  ${GridOrPortrait(`
    gap: 0px;
    flex-direction: row;
    border-radius: 50px 0 0 50px;
    padding: 8px 8px 8px 15px;
  `)}

  &&&.grid-view {
    padding: 4px 8px 4px 15px;
  }
`

const Item = styled.span`
  white-space: nowrap;
  font-size: 15px;

  ${GridOrPortrait(`
    margin-left: 5px;

    &:before {
      content: '·';
      color: #a4a4a4;
      margin-right: 5px;
    }

    &.list-view, &.ad-details {
      margin-left: 8px;
      &:before {
        margin-right: 8px;
      }
    }
  
    &:first-child {
      margin-left: 0;
      &:before {
        display: none;
      }
    }
  `)}

  &.list-view, &.ad-details {
    font-size: 20px;
  }

  i {
    height: auto !important;
  }
`

const Price = styled.span`
  font-weight: bold;
`

const PriceDetail = styled.span`
  &:first-child {
    margin-right: 10px;
  }
  font-size: 80%;
`

const WasPrice = styled.span`
  text-decoration: line-through;
`

function SaleDetailsPill({ price, priceDescription, wasPrice, auction, layoutClass }) {
  const localLayoutClass = layoutClass + (auction ? ' has-auction' : '');
  const hasBids = auction?.bids.total > 0;
  const isVeryTightForSpace = hasBids && (localLayoutClass?.includes('in-carousel') || localLayoutClass?.includes('grid-view'));
  const showLabel = !hasBids && !isVeryTightForSpace;
  return <OuterContainer className={localLayoutClass}>
    <InnerContainer className={localLayoutClass}>
      {auction && <>
        {showLabel && <Item className={localLayoutClass}>
          <small><Icon name='gavel' /> Auction</small>
        </Item>}
        <Item className={localLayoutClass}>
          <small>
            {!showLabel && <><Icon name='gavel' />&nbsp;</>}
            <Countdown date={auction.ending.countdownEpoch} completed={auction.ending.countdown} />
          </small>
        </Item>
        {auction?.label?.text ?
          <Item className={localLayoutClass}>
            <small>{auction.label.text}</small>
          </Item>
          : <>
            {hasBids && <Item className={localLayoutClass}>
              <small>{auction.bids.total} bid{auction.bids.total === 1 ? '' : 's'}</small>
            </Item>}
          </>
        }
      </>}
      {(!auction || auction.bids.total > 0) && <Item className={localLayoutClass}>
        {!auction && wasPrice && <PriceDetail>Was <WasPrice>{wasPrice}</WasPrice></PriceDetail>}
        <Price className={localLayoutClass}>{auction?.price.amount || price}</Price>
        {!auction && priceDescription && <InvertedPopup
          content={<small>{priceDescription.full}</small>}
          trigger={<PriceDetail style={{ color: '#ccc' }}>&nbsp;{priceDescription.short}</PriceDetail>}
        />}
      </Item>}
    </InnerContainer>
  </OuterContainer>
}

export default SaleDetailsPill;