import React, { useState, useRef, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import ImageGallery, { } from 'react-image-gallery'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import 'react-image-gallery/styles/css/image-gallery.css'
import layout from '../../services/layout'
import ListingFeatures from '../Ad/ListingFeatures'
import AnalyticEventService from '../../services/AnalyticEventService';
import GalleryCountPill from '../furniture/GalleryCountPill';
import AuctionService from '../../services/AuctionService';
import SaleDetailsPill from '../furniture/SaleDetailsPill'
import ShareWidget from '../Details/ShareWidget'
import WatchAdWidget from '../furniture/WatchAdWidget'
import AdPills from '../furniture/AdPills'
import dynamic from 'next/dynamic'
import ListingAdminDropdown from '../Ad/ListingAdminDropdown'

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

const ReactPlayerContainer = styled.div`
  position: relative;
  height: calc(100% * ${layout.global.imageRatio});

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ReactPlayerTarget = styled.div`
  position: absolute;
  top: 70px;
  left: 70px;
  bottom: 70px;
  right: 70px;
  z-index: 1;
`

const ListingContainer = styled.div`
  padding: 0 0 20px 0;
  
  @media (min-width: ${layout.global.smallMax}) {
    &.grid-view {
      max-width: 33.33333%;
      padding-right: 10px;

      @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
        max-width: 50%;
      }
    }
  }

  @media (max-width: ${layout.global.mobileMax}) {
    padding-right: 0;
  }
  
  &&&.in-carousel {
    max-width: initial;
    padding: 0 30px 0 0;
  }
`

const ListingListItem = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;  
  background-color: #1B1B1B;
  border-radius: 10px;
  position: relative;

  ${props => !props.$alwaysGrid ? `@media (min-width: ${layout.global.smallMax}) {` : ''}
    &.grid-view {
      display: block;
    }
  ${props => !props.$alwaysGrid ? '}' : ''}

  &.stand-out {
    border: 2px solid ${layout.buttons.inactiveOutline};
  }
  
  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    && {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
`

const GalleryContainer = styled.div`
  position: relative;

  @keyframes darkenSmoothly {    
    0%   { filter: brightness(1); }
    100% { filter: brightness(0.5); }
  }

  &.is-showing-view-more {
    img, ${ReactPlayerContainer} {
      filter: brightness(0.5);
      animation-name: darkenSmoothly;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-timing-function: ease;      
    }
  }

  width: 400px;
  height: 266px;

  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    width: initial;
    height: initial;
  }

  ${props => !props.$alwaysGrid ? `@media (min-width: ${layout.global.smallMax}) {` : ''}
  &.grid-view {
    width: initial;
    height: initial;
  }
  ${props => !props.$alwaysGrid ? '}' : ''}

  // Weirdly this fixes the layout for cards on the homepage
  > a > div {
    display: block !important;
  }
`

const ListingImage = styled(Image)`
  border: 0;
  object-fit: cover;
  overflow: hidden;
  width: 100% !important;
  height: auto;
`

const GridOrPortrait = styling => (`
  &.grid-view {
    ${styling}
  }
  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    && {
      ${styling}
    }
  }
`)

const ListingBody = styled.div`
  position: relative;
  font-family: 'Avenir-Book', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  ${GridOrPortrait(`
    padding: 24px 10px 10px 10px;
  `)}

  @media (max-width: ${layout.search.listViewLandscapeMaxWidth}) {
    &.list-view {
      padding: 30px 10px 10px 10px;
    }
  }
`

const ListingTitleRow = styled.div`
  display: flex;
  align-items: flex-start;
`

const ListingLink = styled.div`
  color: #fff;
  margin-right: auto;
  max-width: 100%;
`

const ListingTitle = styled.h3`
  position: relative;
  color: #fff;
  font-weight: 700;
  margin: 0;
  font-size: 24px;
  
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${layout.global.smallMax}) {
    white-space: normal;
  }

  ${props => !props.$alwaysGrid ? `@media (min-width: ${layout.global.smallMax}) {` : ''}
    &.grid-view {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  ${props => !props.$alwaysGrid ? '}' : ''}
`

const ListingDescription = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; 
  font-size: 13px;
  margin: 0;

  font-size: 14px;
  
  @media (max-width: ${layout.adCard.descriptionTruncationMaxWidth}) {
    -webkit-line-clamp: 2;
  }
  
  @media (max-width: ${layout.global.smallMax}) {
    -webkit-line-clamp: 3;
  }

  &.grid-view {
    -webkit-line-clamp: 2;
  }
`

const ListingLocation = styled.span`
  color: #ccc;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${props => !props.$alwaysGrid ? `@media (min-width: ${layout.global.smallMax}) {` : ''}
    &.grid-view {
      max-width: 250px;
      font-size: 12px;
    }
  ${props => !props.$alwaysGrid ? '}' : ''}
`

const ListingActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`

const ListingViewDetails = styled.div`
  a {
    color: white !important;
  }
  &.grid-view {
    display: none;
  }
`

const SlideImageButton = styled.span`
  background-color: rgba(0, 0, 0, 0.5);
  height: 0px;
  padding: 20px 10px;
  &:hover {
    color: white;
  }

  @media (max-width: ${layout.global.mobileMax}) {
    background-color: rgba(0, 0, 0, 0);
  }
`

const PrimaryActions = styled.div`
  white-space: nowrap;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;

  i {
    margin: 0 !important;
    line-height: 22px !important;
    font-size: 22px !important;
    vertical-align: baseline !important;
    width: auto !important;
  }
`

function ListingCard({
  id,
  slug,
  gallery,
  title,
  shortDescription,
  location,
  price,
  priceDescription,
  wasPrice,
  isSold,
  isStandOut,
  layoutClass,
  primaryImageOnly,
  features,
  alwaysGrid,
  placement,
  auction: adAuction,
  classicClassifiedId
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [adItems, setAdItems] = useState(() => gallery.items);
  const [auction, setAuction] = useState(adAuction);
  const galleryRef = useRef(null);
  const router = useRouter();
  const url = `/search/details/${slug}/${id}?source=${placement}`;

  const isViewingFirstImage = currentImageIndex === 0;
  const isViewingLastImage = currentImageIndex === gallery.items.length - 1;

  const createAnalyticEvent = (eventType, imageIndex, metadata) => {
    imageIndex = imageIndex || galleryRef.current.state.currentIndex;
    const item = adItems[imageIndex];
    AnalyticEventService.createAnalyticEvent({
      eventType: eventType,
      ads: [
        {
          id: id
        }
      ],
      metadata: {
        imageIndex: imageIndex,
        type: item.type,
        source: 'PREVIEW',
        ...metadata
      }
    })
  };

  useEffect(() => {
    if (auction) {
      AuctionService.subscribeToAuction(id, placement, setAuction);
      return () => AuctionService.unsubscribeFromAuction(id, placement);
    }
  }, []);

  const galleryItems = adItems.map(item => {
    if (item.type === 'VIDEO') {
      item.renderItem = function RenderVideoItem() {
        return <ReactPlayerContainer>
          <ListingImage alt={title} className={layoutClass} src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAANSURBVBhXY2BgYPgPAAEEAQBwIGULAAAAAElFTkSuQmCC' $alwaysGrid={alwaysGrid} width='500' height='333' unoptimized={true} loading='eager' />
          {!item.url.startsWith('https://www.facebook.com/') && <ReactPlayerTarget onClick={(e) => {
            e.preventDefault();
            setAdItems(adItems.map(item => ({ ...item, isActive: item.slideIndex === currentImageIndex && !item.isActive })));
          }} />}
          <ReactPlayer
            playing={item.isActive}
            muted={item.isMuted}
            onPlay={() => setAdItems(adItems.map(item => ({ ...item, isActive: item.slideIndex === currentImageIndex })))}
            className='react-player'
            width='100%'
            height='100%'
            url={item.url}
            controls={true}
            progressInterval={5000}
            onProgress={(videoProgress) => item.isActive && createAnalyticEvent('AD_VIDEO_PROGRESS', null, videoProgress)}
            config={{
              facebook: {
                attributes: {
                  'data-height': '250px'
                }
              }
            }}
          />
        </ReactPlayerContainer>
      }
    }
    return item;
  });

  return (
    <ListingContainer className={layoutClass}>
      <ListingListItem className={layoutClass + (isStandOut ? ' stand-out' : '')} $alwaysGrid={alwaysGrid}>
        {gallery.imageCount > 0
          ? <GalleryContainer className={layoutClass + ((isViewingLastImage && gallery.totalCount > 1) ? ' is-showing-view-more' : '')} $alwaysGrid={alwaysGrid}>
            <Link href={url}>
              {!primaryImageOnly
                ? <ImageGallery
                  startIndex={currentImageIndex}
                  items={galleryItems}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showThumbnails={false}
                  infinite={false}
                  slideDuration={200}
                  ref={galleryRef}
                  onSlide={(currentIndex) => {
                    createAnalyticEvent('AD_IMAGE_PREVIEW', currentIndex);
                    const newSlideIndex = galleryRef.current.state.currentIndex;
                    setCurrentImageIndex(newSlideIndex)
                    setAdItems(adItems.map(item => ({ ...item, isActive: item.slideIndex === newSlideIndex })))
                  }}
                  onImageError={(event) => console.warn('failed to load preview', event.target && event.target.src)}
                  // AJB: it's recommended we use memoization here, does it cause performance issues if we don't?
                  renderItem={
                    ({ original }) => <ListingImage alt={title} className={layoutClass} src={original} $alwaysGrid={alwaysGrid} width='500' height='333' unoptimized={true} loading='lazy' />
                  }
                  // AJB: it's recommended we use memoization here, does it cause performance issues if we don't?
                  renderLeftNav={
                    (onClick, disabled) => (
                      isViewingFirstImage
                        ? <></>
                        : <SlideImageButton
                          onClick={(e) => {
                            e.preventDefault();
                            onClick();
                            createAnalyticEvent('AD_IMAGE_PREVIEW_CLICK', currentImageIndex - 1);
                          }}
                          disabled={disabled}
                          className="image-gallery-icon image-gallery-left-nav">
                          <Icon name='chevron left' />
                        </SlideImageButton>
                    )
                  }
                  // AJB: it's recommended we use memoization here, does it cause performance issues if we don't?
                  renderRightNav={
                    (onClick, disabled) => (
                      <SlideImageButton
                        onClick={(e) => {
                          e.preventDefault();
                          if (isViewingLastImage) {
                            router.push(url);
                          }
                          else {
                            onClick();
                            createAnalyticEvent('AD_IMAGE_PREVIEW_CLICK', currentImageIndex + 1);
                          }
                        }}
                        className="image-gallery-icon image-gallery-right-nav">
                        <Icon name='chevron right' />
                        {isViewingLastImage && <span className='view-more'>View more</span>}
                      </SlideImageButton>
                    )
                  }
                />
                : <ListingImage alt={title} src={gallery.items[0].original} className={layoutClass} $alwaysGrid={alwaysGrid} width='500' height='333' unoptimized={true} loading='eager' />}
            </Link>
            <GalleryCountPill gallery={gallery} />
          </GalleryContainer>
          : <ListingImage alt={title} src='/no-photos.png' className={layoutClass} $alwaysGrid={alwaysGrid} width='500' height='333' unoptimized={true} loading='eager' />
        }
        <ListingBody className={layoutClass} $alwaysGrid={alwaysGrid}>
          <ListingTitleRow>
            <AdPills isSold={isSold} layoutClass={layoutClass} />
            <ListingLink>
              <Link href={url}>
                <ListingTitle className={layoutClass} $alwaysGrid={alwaysGrid}>{title}</ListingTitle>
              </Link>
            </ListingLink>
            <ListingAdminDropdown ad={{ id, classicClassifiedId }} />
            <SaleDetailsPill price={price} priceDescription={priceDescription} wasPrice={wasPrice} auction={auction} layoutClass={layoutClass} />
          </ListingTitleRow>
          {features && !alwaysGrid && <ListingFeatures auction={auction} features={features} layoutClass={layoutClass} />}
          {/* AJB: is this roughly the right length from the server? */}
          <ListingDescription className={layoutClass} $alwaysGrid={alwaysGrid}>{shortDescription}<br /><br /></ListingDescription>
          <ListingActions className={layoutClass}>
            <ListingLocation className={layoutClass} $alwaysGrid={alwaysGrid}>
              <Icon name='map marker alternate' />
              {location}
            </ListingLocation>
            <PrimaryActions className={layoutClass}>
              <ShareWidget
                adId={id}
                adClassicClassifiedId={classicClassifiedId}
                adTitle={title}
                adShortDescription={shortDescription}
                iconOnly={true}
                source={placement} />
              <WatchAdWidget
                adId={id}
                iconOnly={true}
                source={placement} />
              <ListingViewDetails className={layoutClass}>
                <Link href={url}>
                  <Icon name='chevron right' size='large' />
                </Link>
              </ListingViewDetails>
            </PrimaryActions>
          </ListingActions>
        </ListingBody>
      </ListingListItem>
    </ListingContainer >
  )
}

export default ListingCard
